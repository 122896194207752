/*
 * ****************************************************************************
 * Copyright (C) 2019 Tarana Wireless, Inc.  All Rights Reserved.
 * ****************************************************************************
 */

/**
 * @author Ashish Kumar <akumar@taranawireless.com>
 */

const { MFE_URLS } = window._env_;
window.mfeUrls = MFE_URLS;

import('./bootstrap');
